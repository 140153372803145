<template>
  <div>
    <offer-form
      :offer="offer"
      :submit="addOffer"
    />
  </div>
</template>
<script>
import OfferForm from '@/common/components/Offers/OfferForm.vue'
import Offers from '@/common/compositions/Offers/OffersApi'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'AddOffer',
  components: { OfferForm },
  setup() {
    const { offer, addOfferRequest, prepareFormData } = Offers()
    const { successfulOperationAlert } = handleAlerts()
    return {
      offer, addOfferRequest, prepareFormData, successfulOperationAlert,
    }
  },
  methods: {
    addOffer() {
      this.$set(this.offer, 'classification', 'business')
      this.$set(this.offer, 'organization_id', this.$store.getters['mainEntity/getEntityId'])
      this.prepareFormData()
      return this.addOfferRequest().then(() => {
        this.successfulOperationAlert('Offer is added successfully')
        this.$router.push({ name: 'offers-list' })
      })
    },
  },
}
</script>
<style lang="">

</style>
